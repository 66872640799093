<template>
  <div class="flex-center flex-1">
    <div class="modal__container">
			<transition mode="out-in" name="transition--fade">
        <form
            key="login"
            :class="{'modal__bg border-0 rounded shadow-2xl': !compact}"
            @submit.stop.prevent="login"
        >
          <!-- LOGIN VIEW -->
          <div
              v-if="!compact"
              class="flex items-center flex-shrink-0 p-4 bg-blue-lightest rounded-t"
          >
						<svg-icon
class="mr-1 fill-current text-blue-lighter"
											height="24"
											name="lock-open" width="24"/>
            <span class="text-xl text-blue-darkest leading-snug">{{
                $t('LOG_IN')
              }}</span>
          </div>
          <div class="modal__content modal__content--padded">
            <div v-if="loginErrorMsg !== ''" class="mb-4">
              <p class="box box--error" v-html="loginErrorMsg" />
            </div>

            <div style="margin-top: 1rem">
              <button
                  class="btn btn--primary btn--large btn--block"
                  :class="{'rounded': rounded}"
                  :disabled="loginButtonClicked"
                  type="submit"
              >
                <i class="icon h-space--right--small">
									<svg-icon height="24" name="lock-open" width="24"/>
                </i>
                <span>{{ $t('LOG_IN') }}</span>
              </button>
            </div>
            <div v-if="!compact" style="margin-top: 1rem">
              <ul class="inline-list inline-list--light inline-list--center">
                <li v-if="newRegistrationEnabled">
                    <a href="/register/new-forest-owner" class="btn--link btn--link--lookalike text-blue-darkest">{{$t('REGISTER_NEW_USER')}}</a>
                </li>
                <li>
                  <show-privacy-information-button
                      class="btn--link btn--link--lookalike text-blue-darkest"
                  />
                </li>
              </ul>
            </div>
          </div>
        </form>
      </transition>
    </div>
  </div>
</template>
<script>
import ShowPrivacyInformationButton from '@/components/buttons/ShowPrivacyInformationButton.vue'
import {useUserStore} from "~/stores/user";

export default {
	components: {
		ShowPrivacyInformationButton,
	},
  props: {
        compact: {
        type: Boolean,
        default: false,
        },
        rounded: {
        type: Boolean,
        default: false,
        },
        loginContext: { // The login can be in a context of a forestManagementPlan to redirect back there, or just falsy for normal login
            type: String 
        }
    },
	setup() {
		const userStore = useUserStore()
		return {
			userStore,
		}
	},
  data() {
    return {
      newRegistrationEnabled: this.$config.public.features.newRegistration,
      loginButtonClicked: false
    }
  },
  computed: {
    loginErrorMsg() {
      return this.$t(this.userStore.loginErrorMsg)
    },
  },
	mounted() {
		this.setFocus()
  },
  methods: {
    login() {
      this.loginButtonClicked = true;
      this.userStore.login({
        impersonate: this.$route.query.impersonate === '1',
        loginContext: this.loginContext
      })
    },
    setFocus() {
      setTimeout(() => {
        if (this.$refs.hasOwnProperty('focus') && this.$refs.focus) {
          this.$refs.focus.focus()
        }
      }, 600)
    },
  },
}
</script>
